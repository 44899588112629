import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CssBaseline,
  Button,
  Grid,
  Radio,
} from '@mui/material';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const BusinessesList = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useContext(AuthContext);
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);

  useEffect(() => {
    // Retrieve the selected business ID from localStorage
    const storedBusinessId = localStorage.getItem('selectedBusinessId');
    if (storedBusinessId) {
      setSelectedBusinessId(storedBusinessId);
    }
  }, []);

  useEffect(() => {
    if (token) {
      fetch(`${baseUrl}/api/business/list/`, {
        method: 'GET',
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => setData(data))
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    }
  }, [token]);

  // Store the selected business ID in localStorage whenever it changes
  useEffect(() => {
    if (selectedBusinessId !== null) {
      localStorage.setItem('selectedBusinessId', selectedBusinessId);
    }
  }, [selectedBusinessId]);

  if (loading) {
    return null;
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <CssBaseline />
      <Box mb={2}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h4" align="left" gutterBottom>
              Businesses
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => alert('Add Business button clicked')}
            >
              Add Business
            </Button>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                Business Name
              </TableCell>
              <TableCell sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                Address
              </TableCell>
              <TableCell sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                Turnover
              </TableCell>
              <TableCell sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                Select
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data.map((business, index) => (
                <TableRow
                  key={index}
                  hover
                  onClick={() => setSelectedBusinessId(String(business.id))}
                  sx={{ cursor: 'pointer' }} // This makes the row appear clickable
                >
                  <TableCell>{business.name}</TableCell>
                  <TableCell>{business.address}</TableCell>
                  <TableCell>{`£${Math.round(business.turnover).toLocaleString('en-GB')}`}</TableCell>
                  <TableCell>
                    <Radio
                      checked={selectedBusinessId === String(business.id)}
                      onChange={() => setSelectedBusinessId(String(business.id))}
                      value={business.id}
                      name="business-radio-button"
                      inputProps={{ 'aria-label': business.name }}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography align="center" color="textSecondary">
                    No businesses found.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BusinessesList;
