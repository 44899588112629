// src/utils/dateUtils.js

export const calculateDateRange = (timeframe) => {
  const endDate = new Date();
  let startDate;

  switch (timeframe) {
    case 'Last 7 Days':
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 7);
      break;
    case 'Last 30 Days':
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 30);
      break;
    case 'Last 90 Days':
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 90);
      break;
    case 'Last 365 Days':
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 365);
      break;
    case 'All Time':
      startDate = new Date(0); // January 1, 1970
      break;
    default:
      startDate = new Date(0);
  }

  // Set time to the start of the day for startDate
  startDate.setHours(0, 0, 0, 0);

  // Set time to the end of the day for endDate
  endDate.setHours(23, 59, 59, 999);

  return { startDate, endDate };
};
