import { createTheme } from '@mui/material/styles';

// Create a custom theme
const theme = createTheme({
  palette: {
    background: {
      default: '#f4f6f8',
      paper: '#ffffff',
    },
  },
  typography: {
    title: {
      fontFamily: 'Arial, sans-serif',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      textAlign: 'center',
      color: '#000000',
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
      textAlign: 'left',
      color: '#000000',
      marginBottom: 10,
    },
    // Add barLabel typography style
    barLabel: {
      textTransform: 'uppercase', // Force uppercase text
      fontSize: '14px',
      fontWeight: 'bold',
      fill: 'white', // Custom color for bar labels
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          height: '500px',
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          flexGrow: 1,
          padding: '16px',
        },
      },
    },
    // Add chart margin styles
    MuiCharts: {
      styleOverrides: {
        chartMargins: {
          top: 20,
          right: 30,
          bottom: 20,
          left: 80,
        },
      },
    },
  },
});

export default theme;
