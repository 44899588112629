// OverallRatingOverTimeChart.js
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { AuthContext } from '../AuthContext';
import { TimeframeContext } from './Header';
import { calculateDateRange } from '../utils/dateUtils';
import { Box, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export default function OverallRatingOverTimeChart() {
  const [reviews, setReviews] = useState([]);
  const selectedBusinessId = localStorage.getItem('selectedBusinessId');
  const { token } = useContext(AuthContext);
  const timeframe = useContext(TimeframeContext);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/reviews/business/${selectedBusinessId}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        const data = await response.json();
        setReviews(data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews();
  }, [selectedBusinessId, token]);

  // Sort reviews in chronological order
  const sortedReviews = useMemo(() => {
    return [...reviews].sort(
      (a, b) => new Date(a.review_datetime) - new Date(b.review_datetime)
    );
  }, [reviews]);

  // Calculate cumulative average ratings over all reviews
  const cumulativeRatings = useMemo(() => {
    const ratings = [];
    let sum = 0;
    sortedReviews.forEach((review, index) => {
      sum += review.review_stars;
      ratings.push(sum / (index + 1));
    });
    return ratings;
  }, [sortedReviews]);

  // Get dates from sorted reviews
  const dates = useMemo(
    () => sortedReviews.map((review) => new Date(review.review_datetime)),
    [sortedReviews]
  );

  // Calculate the date range based on the selected timeframe
  const { startDate, endDate } = useMemo(
    () => calculateDateRange(timeframe),
    [timeframe]
  );

  // Filter dates and cumulative ratings based on the timeframe
  const filteredData = useMemo(() => {
    const filteredDates = [];
    const filteredRatings = [];
    dates.forEach((date, index) => {
      if (date >= startDate && date <= endDate) {
        filteredDates.push(date);
        filteredRatings.push(cumulativeRatings[index]);
      }
    });
    return { filteredDates, filteredRatings };
  }, [dates, cumulativeRatings, startDate, endDate]);

  const lineColor = '#4682B4'; // Steel Blue
// Calculate the delta in overall rating for the selected timeframe
const deltaRating = useMemo(() => {
  const filteredRatings = filteredData.filteredRatings;
  if (filteredRatings.length > 1) {
    return filteredRatings[filteredRatings.length - 1] - filteredRatings[0];
  }
  return 0; // No change if there is only one or no rating
}, [filteredData.filteredRatings]);

return (
  <Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">Overall Rating Over Time</Typography>
      <Box display="flex" alignItems="center" sx={{ textAlign: 'right' }}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 'bold', fontSize: '1.25rem', marginRight: '8px' }}
        >
          {deltaRating.toFixed(3)}
        </Typography>
        {deltaRating > 0 ? (
          <ArrowUpwardIcon />
        ) : deltaRating < 0 ? (
          <ArrowDownwardIcon />
        ) : null}
      </Box>
    </Box>
    <Box
      className="custom-y-padding"
      sx={{ height: 400 }} // Set the height of the chart to match others
    >
      <LineChart
        xAxis={[
          {
            data: filteredData.filteredDates,
            scaleType: 'time',
            labelFormatter: (date) => {
              const options = {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              };
              return new Date(date).toLocaleString(undefined, options);
            },
          },
        ]}
        yAxis={[
          {
            min: 0,
            max: 5,
            label: 'Rating',
            valueFormatter: (value) => value.toFixed(1),
          },
        ]}
        series={[
          {
            data: filteredData.filteredRatings,
            name: 'Cumulative Rating',
            color: lineColor,
            markerSize: 0,
            showMark: false,
            fillOpacity: 0.3,
          },
        ]}
        grid={{ horizontal: true }}
        margin={{ top: 20, right: 30, bottom: 40, left: 80 }}
        height={260} // This sets the height of the chart itself
        tooltip={{}}
      />
    </Box>
  </Box>
);

}
