import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";  // Import useNavigate

function Success() {
  const navigate = useNavigate();  // Initialize useNavigate for redirection

  useEffect(() => {
    // Redirect to the Dashboard when the component mounts
    navigate('/signup');
  }, [navigate]);

  return null;  // Render nothing since we're just redirecting
}

export default Success;
