import React from "react";

function Myaccount() {
  return (
    <div>
        Myaccount
    </div>
  );
}

export default Myaccount;
