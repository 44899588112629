// src/components/overall_rating_chart.js
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Box, Typography, useTheme } from '@mui/material';
import { AuthContext } from '../AuthContext';
import { DisplayModeContext, TimeframeContext } from './Header'; // Reintroduced TimeframeContext
import PropTypes from 'prop-types';
import { calculateDateRange } from '../utils/dateUtils'; // Ensure this function is correctly imported

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export default function OverallRatingChart({ onResetFilters }) {
  const [averageRating, setAverageRating] = useState(0);
  const [turnover, setTurnover] = useState(null);
  const [reviewsData, setReviewsData] = useState([]); // Added state for reviewsData
  const [loading, setLoading] = useState(true);

  // **New State Variables for Totals**
  const [totalRatingImpact, setTotalRatingImpact] = useState(0);
  const [totalSalesImpact, setTotalSalesImpact] = useState(0);

  const { token } = useContext(AuthContext);
  const displayMode = useContext(DisplayModeContext);
  const timeframe = useContext(TimeframeContext); // Reintroduced TimeframeContext
  const selectedBusinessId = localStorage.getItem('selectedBusinessId');
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      if (selectedBusinessId && token) {
        try {
          // Fetch business details
          const businessResponse = await fetch(
            `${baseUrl}/api/business/view/${selectedBusinessId}/`,
            {
              method: 'GET',
              headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          if (!businessResponse.ok) {
            throw new Error(`HTTP error! status: ${businessResponse.status}`);
          }
          const businessData = await businessResponse.json();
          setTurnover(businessData.turnover);

          // Fetch all reviews without filtering by date
          const reviewsResponse = await fetch(
            `${baseUrl}/api/reviews/business/${selectedBusinessId}/`,
            {
              method: 'GET',
              headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          if (!reviewsResponse.ok) {
            throw new Error(`HTTP error! status: ${reviewsResponse.status}`);
          }
          const reviewsData = await reviewsResponse.json();
          reviewsData.sort(
            (a, b) =>
              new Date(a.review_datetime) - new Date(b.review_datetime)
          );
          setReviewsData(reviewsData); // Store reviewsData in state

          // Calculate average rating from all reviews
          if (reviewsData.length > 0) {
            const total = reviewsData.reduce(
              (acc, review) => acc + review.review_stars,
              0
            );
            const avg = total / reviewsData.length;
            setAverageRating(parseFloat(avg.toFixed(3)));
          } else {
            setAverageRating(0);
          }

          // **New Code: Fetch Analyses and Comments to Compute Totals**
          // Fetch analyses
          const analysesResponse = await fetch(
            `${baseUrl}/api/analyses/business/${selectedBusinessId}/`,
            {
              method: 'GET',
              headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          if (!analysesResponse.ok) {
            throw new Error(`HTTP error! status: ${analysesResponse.status}`);
          }
          const analyses = await analysesResponse.json();
          if (analyses.length === 0) {
            throw new Error('No analyses found.');
          }
          const firstAnalysis = analyses[0];

          // Fetch comments
          const commentsResponse = await fetch(
            `${baseUrl}/api/analyses/${firstAnalysis.id}/comments/`,
            {
              method: 'GET',
              headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          if (!commentsResponse.ok) {
            throw new Error(`HTTP error! status: ${commentsResponse.status}`);
          }
          const comments = await commentsResponse.json();
          if (!Array.isArray(comments)) {
            throw new Error('Invalid data format for comments.');
          }

          // **Filter comments based on timeframe**
          const { startDate, endDate } = calculateDateRange(timeframe);
          const filteredComments = comments.filter((comment) => {
            const reviewDate = new Date(comment.review.review_datetime);
            return reviewDate >= startDate && reviewDate <= endDate;
          });

          // **Aggregate Stars Contribution**
          let totalRatingImpactAccum = 0;

          filteredComments.forEach((comment) => {
            const contribution = Math.abs(comment.stars_contribution);
            totalRatingImpactAccum += contribution;
          });

          setTotalRatingImpact(totalRatingImpactAccum / reviewsData.length);

          // **Calculate the timeframe factor**
          const timeDifferenceInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
          const timeframeFactor = timeframe === 'All Time' && reviewsData.length > 0
            ? timeDifferenceInDays / ((new Date() - new Date(reviewsData[0].review_datetime)) / (1000 * 60 * 60 * 24))
            : timeDifferenceInDays / 365;

          // **Compute Total Sales Impact with the timeframe factor**
          if (reviewsData.length > 0 && typeof businessData.turnover === 'number') {
            const totalSales = (totalRatingImpactAccum / reviewsData.length) * 0.1 * businessData.turnover;
            setTotalSalesImpact(totalSales);
          } else {
            setTotalSalesImpact(0);
          }

        } catch (error) {
          console.error('Error fetching data:', error);
          setTurnover(null);
          setAverageRating(0);
          setTotalRatingImpact(0);
          setTotalSalesImpact(0);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedBusinessId, token, timeframe, displayMode]);

  const potentialTurnover = useMemo(() => {
    if (
      displayMode === 'sales' &&
      turnover &&
      typeof turnover === 'number' &&
      reviewsData.length > 0
    ) {
      let { startDate, endDate } = calculateDateRange(timeframe);

      // **Adjust startDate for 'All Time' timeframe using the earliest review date**
      if (timeframe === 'All Time') {
        const earliestReviewDate = new Date(
          reviewsData[0].review_datetime
        );
        startDate = earliestReviewDate;
      }

      const timeDifferenceInDays =
        (endDate - startDate) / (1000 * 60 * 60 * 24);

      const timeframeFactor = timeDifferenceInDays / 365;

      const scaledTurnover = turnover * timeframeFactor;
      return scaledTurnover;
    }
    return null;
  }, [displayMode, turnover, timeframe, reviewsData]);

  const yAxisConfig = useMemo(() => {
    return [
      {
        scaleType: 'linear',
        label: displayMode === 'sales' ? 'Revenue (£)' : 'Rating',
        min: 0,
        max: displayMode === 'rating' ? 5 : undefined,
        valueFormatter: (value) =>
          displayMode === 'sales'
            ? new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
                notation: 'compact',
                compactDisplay: 'short',
                maximumFractionDigits: 0,
              })
                .format(value)
                .toLowerCase()
            : `${value.toFixed(1)}`,
      },
    ];
  }, [displayMode, potentialTurnover]);

  const xAxisConfig = useMemo(() => {
    return [
      {
        scaleType: 'band',
        label: '',
        data: displayMode === 'sales' ? ['Sales Impact'] : ['Rating Impact'],
      },
    ];
  }, [displayMode]);

  const seriesData = useMemo(() => {
    return [
      {
        data: [displayMode === 'sales' ? Math.abs(potentialTurnover) : averageRating],
        id: displayMode === 'sales' ? 'turnover' : 'averageRating',
        label: displayMode === 'sales' ? 'Turnover' : 'Overall Rating',
        stack: 'total',
        color: '#4682B4',
      },
      {
        data: [displayMode === 'sales' ? Math.abs(totalSalesImpact) : totalRatingImpact], // Add totalSalesImpact for sales mode
        id: 'totalSalesImpact',
        label: displayMode === 'sales' ? 'Sales Impact' : 'Rating Impact',
        stack: 'total',
        color: '#FFA07A',
      },
    ];
  }, [displayMode, potentialTurnover, averageRating, totalSalesImpact, totalRatingImpact]);


  const handleElementClick = (event) => {
    const target = event.target;
    if (target) {
      const className = target.getAttribute('class') || '';
      const match = className.match(/MuiBarElement-series-([\w-]+)/);
      if (match && match[1]) {
        if (onResetFilters) {
          onResetFilters();
        }
      }
    }
  };

  // **Compute the Formatted Totals**
  const formattedTotal = useMemo(() => {
    if (displayMode === 'sales') {
      return `Total Sales Impact: £${Math.round(totalSalesImpact).toLocaleString('en-GB')}`;
    } else {
      return `Total Rating Impact: ${totalRatingImpact.toFixed(2)} Stars`;
    }
  }, [displayMode, totalSalesImpact, totalRatingImpact]);

  if (loading) {
    return (
      <Box>
        <Typography
          variant="h5" // Changed to use theme's h5 variant
        >
          {displayMode === 'rating' ? 'Overall Rating' : 'Turnover'}
        </Typography>
        <Typography variant="body1" align="left">
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography
        variant="h5" // Changed to use theme's h5 variant
        gutterBottom
      >
        {displayMode === 'rating' ? 'Overall Rating' : 'Turnover'}
      </Typography>
      <Box className="custom-y-padding" sx={{ height: 400, marginTop: 2, marginBottom: 2 }}>
        <BarChart
          xAxis={xAxisConfig}
          yAxis={yAxisConfig}
          series={seriesData}
          barSize={50}
          padding={0.2}
          margin={theme.components.MuiCharts.styleOverrides.chartMargins}
          colors={seriesData.map((s) => s.color)}
          slotProps={{
            legend: { hidden: true },
            xAxis: {
              label: {
                style: {
                  fontSize: 14,
                  fontWeight: theme.typography.fontWeightMedium,
                  fill: theme.palette.text.primary,
                },
              },
              tick: {
                style: {
                  fontSize: 12,
                  fill: theme.palette.text.secondary,
                },
              },
            },
            yAxis: {
              label: {
                style: {
                  fontSize: 14,
                  fontWeight: theme.typography.fontWeightMedium,
                  fill: theme.palette.text.primary,
                },
              },
              tick: {
                style: {
                  fontSize: 12,
                  fill: theme.palette.text.secondary,
                },
              },
            },
            barLabel: {
              position: 'top',
              style: {
                fontSize: 16,
                fontWeight: theme.typography.fontWeightBold,
                fill: theme.palette.text.primary,
              },
            },
          }}
          barLabel={({ value }) =>
            displayMode === 'sales'
              ? `£${Math.round(Math.abs(value)).toLocaleString('en-GB')}` // Made absolute
              : `${value.toFixed(1)}`
          }
          tooltip={{
            shared: false,
            formatter: (params) => {
              const value = params.value;
              return displayMode === 'sales'
                ? `£${Math.round(Math.abs(value)).toLocaleString('en-GB')}` // Made absolute
                : `${value.toFixed(4)} Stars`;
            },
          }}
          onClick={handleElementClick}
        />
      </Box>
    </Box>
  );
}

OverallRatingChart.propTypes = {
  onResetFilters: PropTypes.func,
};
