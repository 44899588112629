// Header.js
import React, { useState, useContext, useEffect, createContext } from 'react';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { ListItemIcon } from '@mui/material';
import {
  Home as HomeIcon,
  RateReview as RateReviewIcon,
  Business as BusinessIcon,
} from '@mui/icons-material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const drawerWidth = 200;

// Create contexts
export const DisplayModeContext = createContext();
export const TimeframeContext = createContext(); // Ensure TimeframeContext is exported

// Styled FormControl to match ToggleButtonGroup without InputLabel
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: 150,
  marginRight: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    border: '1px solid #1976d2',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1976d2',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1976d2',
    },
  },
  '& .MuiSelect-select': {
    padding: '8px 12px',
    color: '#000',
  },
}));

export default function Header({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { setToken, token } = useContext(AuthContext);

  // Settings menu state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Business name state
  const [businessName, setBusinessName] = useState('');

  // Display mode state
  const [displayMode, setDisplayMode] = useState('sales');

  // Timeframe state
  const [timeframe, setTimeframe] = useState('Last 90 Days');

  const selectedBusinessId = localStorage.getItem('selectedBusinessId');

  useEffect(() => {
    const fetchBusinessName = async () => {
      if (selectedBusinessId && token) {
        try {
          const response = await fetch(
            `${baseUrl}/api/business/view/${selectedBusinessId}/`,
            {
              method: 'GET',
              headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setBusinessName(data.name);
        } catch (error) {
          console.error('Error fetching business name:', error);
          setBusinessName('Business Name');
        }
      } else {
        setBusinessName('Business Name');
      }
    };

    fetchBusinessName();

    const handleStorageChange = () => {
      fetchBusinessName();
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [token, selectedBusinessId]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setToken('');
    handleClose();
    navigate('/login');
  };

  // Handle display mode change
  const handleDisplayModeChange = (event, newMode) => {
    if (newMode !== null) {
      setDisplayMode(newMode);
    }
  };

  // Handle timeframe change
  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  // Define the menu items
  const menuItems = [
    {
      text: 'Performance',
      icon: <HomeIcon />,
      path: '/Dashboard',
    },
    {
      text: 'Reviews',
      icon: <RateReviewIcon />,
      path: '/Reviews',
    },
    {
      text: 'Businesses',
      icon: <BusinessIcon />,
      path: '/Businesses',
    },
  ];

  return (
    <DisplayModeContext.Provider value={displayMode}>
      <TimeframeContext.Provider value={timeframe}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />

          {/* AppBar for the top bar */}
          <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
              <Typography variant="h6" noWrap sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                Insight
              </Typography>
              {/* Display selected business name */}
              <Typography
                variant="h6"
                noWrap
                sx={{
                  position: 'absolute',
                  left: drawerWidth + 24,
                  fontWeight: 'medium',
                }}
              >
                {businessName}
              </Typography>

              {/* Swapped: Toggle Button for Display Mode */}
              <ToggleButtonGroup
                value={displayMode}
                exclusive
                onChange={handleDisplayModeChange}
                aria-label="display mode"
                size="small"
                sx={{
                  width: 150,
                  marginRight: 2,
                  border: '1px solid #1976d2',
                  borderRadius: '8px',
                  backgroundColor: '#ffffff',
                  boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
                }}
              >
                                <ToggleButton
                  value="sales"
                  aria-label="sales impact"
                  sx={{
                    flex: 1,
                    color: displayMode === 'sales' ? '#1976d2' : '#000',
                    backgroundColor: displayMode === 'sales' ? '#e3f2fd' : '#fff',
                    '&:hover': {
                      backgroundColor: '#bbdefb',
                    },
                  }}
                >
                  Sales
                </ToggleButton>
                <ToggleButton
                  value="rating"
                  aria-label="rating impact"
                  sx={{
                    flex: 1,
                    color: displayMode === 'rating' ? '#1976d2' : '#000',
                    backgroundColor: displayMode === 'rating' ? '#e3f2fd' : '#fff',
                    '&:hover': {
                      backgroundColor: '#bbdefb',
                    },
                  }}
                >
                  Rating
                </ToggleButton>

              </ToggleButtonGroup>

              {/* Swapped: Timeframe Selector without InputLabel */}
              <StyledFormControl variant="outlined" size="small">
                <Select
                  id="timeframe-select"
                  value={timeframe}
                  onChange={handleTimeframeChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Timeframe' }}
                >
                  <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>
                  <MenuItem value="Last 30 Days">Last 30 Days</MenuItem>
                  <MenuItem value="Last 90 Days">Last 90 Days</MenuItem>
                  <MenuItem value="Last 365 Days">Last 365 Days</MenuItem>
                  <MenuItem value="All Time">All Time</MenuItem>
                </Select>
              </StyledFormControl>

              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="settings"
                aria-controls="settings-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <SettingsIcon />
              </IconButton>
              <Menu
                id="settings-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'settings-button',
                }}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>

          {/* Sidebar */}
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Toolbar />
            <List>
              {menuItems.map((item) => (
                <ListItemButton
                  key={item.text}
                  selected={location.pathname === item.path}
                  onClick={() => navigate(item.path)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Drawer>

          {/* Main content */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              marginTop: '64px',
            }}
          >
            {children}
          </Box>
        </Box>
      </TimeframeContext.Provider>
    </DisplayModeContext.Provider>
  );
}

Header.propTypes = {
  children: PropTypes.node,
};
