import React from "react";

function Logout() {
  return (
    <div>
        Logout
    </div>
  );
}

export default Logout;
