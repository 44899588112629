import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";  // Import useNavigate

function Home() {
  const navigate = useNavigate();  // Initialize useNavigate for redirection

  useEffect(() => {
    // Redirect to the Dashboard when the component mounts
    navigate('/dashboard');
  }, [navigate]);

  return null;  // Render nothing since we're just redirecting
}

export default Home;
